<!--
	程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
	技术支持：Tech@21gmail.com
	单元名称：系统管理流程设置
	开始时间：2021-01-14
	开发人员：万攀,康泽鑫
	最后修改：2021-07-02
	备注说明：如需修改请联系开发人员
-->
<template>
  <div class="workflowBox systemItemIndex">
    <el-tabs v-model="activate" style="height:100%;" tab-position="left" @tab-click="getWorkflowSetupById($event)">
      <el-tab-pane v-for="(elem) in NodeClassfiy" :key="elem.id" :label="elem.name" :name="elem.id+'' " v-if="isPower">
        <!-- 头部新增按钮 -->
        <div class="workflowHead">
          <el-button type="primary" size="mini" @click="show_addBox">新增</el-button>
        </div>
        <div class="el-divider"></div>
        <!-- 流程内容 -->
        <div class="workflowBody">
          <div class="workflowItem" v-for="(process,pIndex) in orderFlowData" :key="pIndex">
            <div class="workflowItemTitle">
              <div class="itemTitleTime">
                {{process.create_time}}
              </div>
              <div class="itemTitleName">
                {{process.name}}
              </div>
              <div class="itemTitleOperation" v-if="process.is_online!=1">
                <i class="icon iconfont icon-xingzhuang29 alibabaicon" title="编辑" @click="show_Dialog(process)"></i>
                <i class="icon iconfont icon-lajitong1 alibabaicon" title="删除" @click="deleteWorkFlow(process.id)"></i>
                <p title="设为默认" @click="setDefault(process)">
                  <b :class="{'orderIsDefault':true,'choiced':process.is_default==1}"></b>
                  <span>设为默认</span>
                </p>
              </div>
              <!--<div class="orderIsOnline" v-else>官网默认流程</div>-->
            </div>
            <!--循环流程状态的部分-->
            <div class="workflowItemBody" v-if="process.is_online!=1">
              <div class="processNodeBox" v-for="(elem,index) in process.statusList" :key="index">
                <el-tooltip placement="top">
                  <div slot="content">{{elem.name}}</div>
                  <div class="processNodeText" :class="{processNodeSty:elem.name=='完成'?true:false}">
                    <div class="processNodeName" :title="elem.name">
                      <i class="icon iconfont icon-dui alibabaicon"></i>
                    </div>
                    <p :title="elem.name">{{elem.name}}</p>
                    <div class="processNodeAb" :title="elem.name"
                      @click="reomveProcessNode(process,process.statusList,index)">
                      <i class="icon iconfont icon-shanchu alibabaicon"></i>
                      <p :title="elem.name">{{elem.name}}</p>
                    </div>
                  </div>
                </el-tooltip>
                <div class="processNodeLine" v-show="index != process.statusList.length-1">
                  <i class="icon iconfont icon-add2 alibabaicon"
                    @click="show_addProcessNode(process,index,process.statusList)" title="添加"></i>
                  <div></div>
                  <i @click="swapNode(process,process.statusList,index)" class="icon iconfont icon-paixu alibabaicon"
                    title="调换顺序" v-show="(index == (process.statusList.length-2))?false:true"></i>
                </div>
              </div>
              <div class="clear"></div>
            </div>
            <!--官网默认流程
            <div class="cliProcessNodeLeft" v-else>
              <div class="processNodeBox" v-for="(elem,index) in process.statusList" :key="index">
                <div class="processNodeText processNodeSty">
                  <div class="processNodeName" :title="elem.name">
                    <i class="icon iconfont icon-dui alibabaicon"></i>
                  </div>
                  <p>{{elem.name}}</p>
                </div>
                <div class="processNodeLine">
                  <i class="icon iconfont"></i>
                  <i class="icon iconfont" style="cursor: default;"></i>
                </div>
              </div>
              <div class="clear"></div>
            </div>-->
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>

    <!-- 新增、编辑弹框 -->
    <el-dialog :title="title" :visible.sync="dialogVisible" width="480px" append-to-body style="overflow-y: hidden;"
      :before-close="cancleWorkFlow">
      <div style="max-height: 420px; width:450px; overflow-y: auto;">
        <div class="billing_dialog_form scollStyle addWorkBox">
          <el-form :model="formData" :rules="Add_Edit_rules" :ref="ref" label-width="120px" class="demo-ruleForm"
            size="small">
            <!-- 分行显示 -->
            <el-row>
              <!-- 分列显示,span的值为24/列数 如:一行一列为24  一行两列为12-->
              <el-col :span="24">
                <el-form-item label="流程名称：" prop="name">
                  <el-input style="width: 215px;" v-model="formData.name" placeholder="选择输入流程名称"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="作用单据：" prop="classifyType">
                  <el-select v-model="formData.classifyType" placeholder="选择开始作用单据分类" size="small" class="mulInput"
                    @change="selectChange('classifyType')">
                    <el-option v-for="(item,index) in billsClassifyData"
                      @click.native="getbillsClassifyId(dialog,item.id)" :key="item.id" :label="item.name"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="单据模板：" prop="billsModel">
                  <el-select v-model="formData.billsModel" placeholder="选择开始作用单据分类" multiple collapse-tags size="small" class="mulInput"
                    @change="selectChange('billsModel')">
                    <el-option v-for="(item,index) in billsModelData" :key="item.id" :label="item.bills_type"
                      :value="item.id" v-show="item.type_classify == formData.classifyType">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="默 认：" prop="default">
                  <el-radio-group v-model="formData.default" style="margin-top: 8px;">
                    <el-radio label="1">是</el-radio>
                    <el-radio label="0">否</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="类 型：" prop="type">
                  <el-radio-group v-model="formData.type" style="margin-top: 8px;">
                    <el-radio label="0">对内</el-radio>
                    <el-radio label="1">对外</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="开 始：" prop="startName">
                  <el-select v-model="formData.startName" placeholder="选择开始流程节点" size="small" class="mulInput"
                    @change="selectChange('startName')">
                    <el-option v-for="(item,index) in show_status" @click.native="getStarStatusId(dialog,item.id)"
                      :label="item.name" :value="item.id" :key="item.id"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="过 程：">
                  <i class="el-icon-circle-plus" style="margin-top: 8px;" @click="addWork"></i>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row v-for="(item,indexI) in arr">
              <el-col :span="24">
                <el-form-item prop="nodename">
                  <span slot="label">过程{{indexI+1}}</span>
                  <el-select v-model="item.name" placeholder="选择流程节点" size="small" class="mulInput">
                    <el-option v-for="(obj,indexJ) in show_status" @click.native="getStatusId(obj.id,indexI,dialog)"
                      :label="obj.name" :value="obj.name"></el-option>
                  </el-select>
                  <i class="el-icon-error mulIcon" style="margin-left: 10px;" @click="removeWork(indexI)"></i>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="结 束：">
                  <span>完 成</span>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancleWorkFlow" size="small">取 消</el-button>
        <el-button type="primary" @click="dialog=='fromData'?addWorkFlow(fromData):updateWorkFlow(currentId,formData)"
          size="small"> 确 定
        </el-button>
      </span>
    </el-dialog>

    <!-- 插入弹框 -->
    <el-dialog title="插入节点" :visible.sync="insertionVisible" width="400px" append-to-body
      :before-close="cancleWorkFlowInsert">
      <div class="billing_dialog_form scollStyle addWorkBox">
        <el-form :model="insertData" :rules="insert_rules" ref="insert_ruleform" label-width="120px"
          class="demo-ruleForm" size="small">
          <!-- 分行显示 -->
          <el-row>
            <!-- 分列显示,span的值为24/列数 如:一行一列为24  一行两列为12-->
            <el-col :span="24">
              <el-form-item label="节 点：" prop="insertionNode">
                <el-select v-model="insertData.insertionNode" placeholder="选择插入流程节点" size="small" class="mulInput">
                  <el-option v-for="(item,index) in show_status" @click.native="getInsertionNode(item.id)"
                    :label="item.name" :value="item.name"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="cancleWorkFlowInsert">取 消</el-button>
        <el-button size="small" type="primary"
          @click="addProcessNode(currentId,cuurentWorkStatusId,currentStatusId,currentIndex)">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  //导入请求
  import api from '@/network/system/workflowSetup'; //流程相关接口

  //vuex相关
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex';

  export default {
    name: 'workflowSetUp',
    data() {
      return {
        isPower: false, //是否有查询权限

        //弹框
        title: '', //弹框标题
        dialog: '', //区分新增和修改表
        formData: {}, //弹框表单数据
        ref: '', //dom标记

        //判断条件
        dialogVisible: false, //新增弹框
        insertionVisible: false, //插入弹框
        activate: '1',

        //当前页面数据
        orderFlowClassfiyId: '', //节点流程分类Id
        curClassifyObjId: -1, //当前界面分类id
        orderFlowData: [], //流程数据
        workFlowOldName: '', //修改时保存流程的旧名字
        statusList: [], //节点数据
        show_status: [], //没有完成节点的节点数据
        editData: {}, //编辑信息
        insertData: { //插入信息
          insertionNode: '', //插入节点
        },
        fromData: {}, //新增弹框数据
        insert_rules: {}, //插入规格验证
        Add_Edit_rules: {}, //新增,编辑规格验证
        currentId: '0', //当前流程Id
        currentStatusId: '0', //当前节点id
        cuurentWorkStatusId: [], //当前流程节点Id字符串
        currentIndex: '0',
        //新增过程数据
        arr: [],
        completeId: '', //完成id

        //新写
        billsClassifyData: [], //作用单据分类数据
        billsClassifyName: '', //单据分类名称
        sourceData: {}, //编辑前数据
        billsModelData: {} //单据模板
      }
    },
    created() {
      this.initVuextData() // 初始化界面所需数据vuex数据
    },
    mounted() {
      this.getWorkflowSetupById({
        name: '1'
      })
      this.initRules(); //表单验证初始化
      this.initData() //初始化数据
    },
    computed: {
      ...mapState({
        WorkFlowSetup: state => state.system.WorkFlowSetup, //流程数据
        billTemplateData: state => state.system.billTemplateData, //所有单据类型数据
        billingClassifyData: state => state.system.billingClassifyData, //单据模块子级分类数据
        NodeClassfiy: state => state.system.NodeClassfiy, //节点分类数据
        userPowerInfo: state => state.user.userPowerInfo, //权限
      })
    },
    methods: {
      ...mapMutations([
        'WORKFLOWSETUP', //流程相关Vuex
      ]),
      ...mapActions([
        'getelectronicTemplate', //获取所有单据类型
        'getAllNodeType', //获取节点分类
        'getUserPowerInfo', //获取用户操作权限
      ]),

      /* 初始化界面所需数据vuex数据 */
      async initVuextData() {
        //判断用户权限(需优化  这里先这样写  不知道为什么await没有用)
        if (this.userPowerInfo.length == 0) {
          await this.getUserPowerInfo();
          //判断是否有查询权限
          if (this.commonJsExtend.isHaveThePower(22, 8, 1, '查询')) {
            return
          }
          this.isPower = true
        } else {
          //判断是否有查询权限
          if (this.commonJsExtend.isHaveThePower(22, 8, 1, '查询')) {
            return
          }
          this.isPower = true
        }
        //判断单据模板数据是否存在
        if (this.billTemplateData.length == 0) {
          await this.getelectronicTemplate();
        }
        //判断节点分类数据是否存在
        if (this.NodeClassfiy.length == 0) {
          await this.getAllNodeType();
        }
      },

      /* 新增流程 */
      addWorkFlow(formData) {
        const {
          name,
          type,
          statusID,
          billsModel,
          classifyType
        } = formData
        let billing_tyoe_namelist=this.billsModelData.filter(item =>  billsModel.includes(item.id))
        let billing_type_name=[]
        billing_tyoe_namelist.forEach(item=>{
          billing_type_name.push(item.bills_type)
        })
        billing_type_name=billing_type_name.join(",")
        let is_default = formData.default,
          range_type = type,
          status_ids = []
        this.$refs['Add_ruleform'].validate(valid => {
          if (valid) {
            this.fromData.statusID.forEach(item => {
              status_ids.push(item)
            })
            //加入'完成'到数组结尾
            status_ids.push(this.completeId)
            //将节点ID数组转化为字符串
            let startId = this.fromData.startOne.id + ''
            status_ids.unshift(startId)
            status_ids = status_ids.join(",");
            let billsModelList=billsModel.join(",");
            var data = {
              type_id: this.orderFlowClassfiyId,
              is_default,
              is_online: '0',
              name,
              range_type,
              status_ids,
              type_classify: classifyType, //单据分类
              billing_type_id: billsModelList, //单据类型id
              billing_type_name //单据类型名称
            }
            // 发送请求前加载loading框
            this.loading = this.commonJsExtend.customLoading("#offer_tableBox", 4, '数据更新中,请稍候...');
            //发送请求
            api.addWorkFlow(data).then(res => {
              this.loading.close() //关闭loading框
              if (res.code == 200) {
                this.getWorkflowSetupById({
                  name: this.orderFlowClassfiyId
                })
                this.$message({
                  type: 'success',
                  message: '新增流程成功',
                  duration: this.elDuration
                })
              } else {
                this.$message({
                  type: 'error',
                  message: res.message,
                  duration: this.elDuration
                })
              }
            })
            //关闭弹框并且清空输入框
            this.cancleWorkFlow()
          }
        })
      },

      /* 获取分类相关的流程数据 */
      async getWorkflowSetupById(event) {

        //获取当前界面分类id
        this.curClassifyObjId = event.name;
        // if(this.orderFlowClassfiyId == event.name){
        //   return
        // }
        this.orderFlowClassfiyId = event.name //保存分类ID
        //定义后端接受参数
        let data = {
          type_id: event.name
        }
        // 发送请求前加载loading框
        this.loading = this.commonJsExtend.customLoading("#offer_tableBox", 4, '数据更新中,请稍候...');
        //发送请求
        await api.getWorkflowSetupById(data).then(res => {
          if (res.code == 200) {
            this.orderFlowData = res.data
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
        let nodeData = {
          type_id: event.name
        }
        //获取节点信息
        await api.getNodeInfoByClassfiyId(nodeData).then(res => {
          if (res.code == 200) {
            this.statusList = res.data
            this.show_status = this.statusList.filter((current, index, array) => {
              return current.name != '完成'
            })
            this.statusList.forEach(item => { //获取完成节点id
              if (item.name == '完成') {
                this.completeId = item.id
              }
            })
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
        this.loading.close();
        this.changeArray()
        this.WORKFLOWSETUP(this.orderFlowData);
      },

      /* 删除流程 */
      deleteWorkFlow(id) {
        //判断是否有删除权限
        if (this.commonJsExtend.isHaveThePower(22, 8, 3, '删除')) {
          return
        }
        this.$confirm('确定要删除吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          // 发送请求前加载loading框
          this.loading = this.commonJsExtend.customLoading("#offer_tableBox", 4, '数据删除中,请稍候...');
          //发送请求
          api.deleteWorkFlow({
            id: id
          }).then(res => {
            this.loading.close() //关闭loading框
            if (res.code == 200) {
              this.getWorkflowSetupById({
                name: this.orderFlowClassfiyId
              })
              this.$message({
                type: 'success',
                message: '删除流程成功',
                duration: 1500
              })
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: 1500
              })
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
            duration: 1500
          })
        })
      },

      /* 编辑流程 */
      updateWorkFlow(id, editData) {
        this.$refs['compile_ruleform'].validate(valid => {
          let status_ids = []
          if (valid) {
            this.editData.statusID.forEach(item => {
              status_ids.push(item)
            })
            //加入'完成'到数组结尾
            status_ids.push(this.completeId)
            //将节点ID数组转化为字符串
            let startId = this.editData.startOne.id + ''
            status_ids.unshift(startId)
            status_ids = status_ids.join(",");
            //日志
            let logContent = ''
            let objFiled = { //定义字典
              name: '流程名称',
              classifyType: '作用单据',
              startName: '开始',
              default: '默认',
              type: '类型',
              billsModel: '单据模板'
            }
            let sourceTypeNameList=''//编辑前单据模板名称列表
            let newTypeNameList=[]//编辑后单据模板名称列表
            let sourceTypeName = [] //编辑前单据模板名称
            let newTypeName = [] //编辑后单据模板名称
            if (this.billsModelData) {
              let soutceTypeData = this.billsModelData.filter(item => this.sourceData['billsModel'].includes(item.id));
              if(soutceTypeData.length>0){
                soutceTypeData.forEach(item=>{
                  sourceTypeName.push(item.bills_type)//编辑前单据模板名称
                })
              }
             newTypeNameList=this.billsModelData.filter(item =>  editData['billsModel'].includes(item.id))
             console.log(newTypeNameList)
             newTypeNameList.forEach(item=>{
               console.log(item.bills_type)
              newTypeName.push(item.bills_type) //编辑后单据模板名称

             })

            }
            let keys = Object.keys(objFiled) //获取字段名
            keys.forEach(item => { //遍历key值进行匹配
              if (editData[item] != this.sourceData[item]) { //匹配出修改的字段
                logContent += '更改了【' + this.billsClassifyName + '】中的【' + objFiled[item] + '】,更改前:' //拼接公共部分
                if (item == 'default') { //当默认发生修改时
                  logContent += (this.sourceData[item] == 0 ? '否' : '是') + ",更改后:" + (editData[item] == 0 ?
                    '否' : '是') + ';' //拼接日志信息
                } else if (item == 'type') { //当类型发生修改时
                  logContent += (this.sourceData[item] == 0 ? '对内' : '对外') + ",更改后:" + (editData[item] == 0 ?
                    '对内' : '对外') + ';' //拼接日志信息
                } else if (item == 'classifyType') { //当类型发生修改时
                  logContent += this.billsClassifyData[this.sourceData[item]].name + ",更改后:" + this
                    .billsClassifyData[editData[item]].name + ';' //拼接日志信息
                } else if (item == 'billsModel') { //当类型发生修改时
                  logContent += sourceTypeName + ",更改后:" + newTypeName + ';' //拼接日志信息
                } else {
                  logContent += this.sourceData[item] + ",更改后:" + editData[item] + ';' //拼接日志信息
                }
              }
            })
            //过程
            if (editData['statusID'].toString() != this.sourceData['statusID'].toString()) { //当过程发生改变时
              logContent += '更改了【' + this.billsClassifyName + '】中的【过程】,更改前:' //拼接公共部分
              let editStatus = '' //定义编辑后过程
              let sourceStatus = '' //定义编辑前过程
              this.arr.forEach((itemI, indexI) => { //遍历过程数组
                this.sourceData['statusID'].forEach(itemJ => { //遍历编辑前过程数组
                  if (itemI.id == itemJ) { //匹配对应名称
                    sourceStatus += itemI.name + ',' //拼接日志
                  }
                })
                //处理:最后一个不加逗号
                if (indexI == this.arr.length - 1) { //当此项为最后一项时
                  editStatus += itemI.name //拼接日志
                } else { //当此项不为最后一项时
                  editStatus += itemI.name + ',' //拼接日志
                }
              })
              logContent += sourceStatus + '更改后:' + editStatus //拼接日志
            }
            console.log(newTypeName)
            // 发送请求前加载loading框
            this.loading = this.commonJsExtend.customLoading("#offer_tableBox", 4, '数据更新中,请稍候...');
            //发送请求

            api.updateWorkFlowStatus({
              id: id,
              name: this.workFlowOldName,
              new_name: editData.name,
              is_default: editData.default,
              range_type: editData.type,
              status_ids: status_ids,
              type_classify: this.editData.billsClassify,
              type_id: this.orderFlowClassfiyId,
              billing_type_id: editData.billsModel.join(","), //单据类型id
              billing_type_name: newTypeName.join(","), //单据类型名称
              logContent //日志
            }).then(res => {
              this.loading.close() //关闭loading框
              if (res.code == 200) {
                this.getWorkflowSetupById({
                  name: this.orderFlowClassfiyId
                })
                this.$message({
                  type: 'success',
                  message: '修改成功',
                  duration: 1500
                })
              } else {
                this.$message({
                  type: 'error',
                  message: res.message,
                  duration: 1500
                })
              }
            });
            //完成之后清空输入框,关闭弹框
            this.cancleWorkFlow()
          }
        })
      },

      /* 设置默认流程 */
      setDefault(data) {
        //判断是否有操作权限
        if (this.commonJsExtend.isHaveThePower(22, 8, 4, '操作')) {
          return
        }
        this.$confirm('确定设置为默认流程？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          // 发送请求前加载loading框
          this.loading = this.commonJsExtend.customLoading("#offer_tableBox", 4, '数据更新中,请稍候...');
          //发送请求
          api.updateWorkFlowStatus({
            id: data.id,
            name: data.name,
            new_name: data.name,
            type_id: this.orderFlowClassfiyId,
            is_default: '1'
          }).then(res => {
            this.loading.close()
            if (res.code == 200) {
              this.getWorkflowSetupById({
                name: this.orderFlowClassfiyId
              })
              this.$message({
                type: 'success',
                message: '设置为默认成功',
                duration: 1500
              })
            } else {
              this.$message({
                type: 'error',
                message: '设置为默认失败',
                duration: 1500
              })
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消设置',
            duration: 1500
          });
        })
      },

      /* 添加插入节点*/
      addProcessNode(id, status_ids, node_id, index) {
        this.$refs['insert_ruleform'].validate(valid => {
          if (valid) {
            for (let i = 0; i < status_ids.length; i++) {
              if (status_ids[i] == node_id) {
                this.$message({
                  type: 'error',
                  message: '不能插入相同的节点',
                })
                return false
              }
            }
            status_ids.splice(index + 1, 0, node_id);
            status_ids = status_ids.join(",")
            //日志拼接
            let logContent = '在【' + this.billsClassifyName + '】的【' + this.workFlowOldName + '】中新增了节点【' + this
              .insertData.insertionNode + '】'
            var data = {
              id,
              name: this.workFlowOldName,
              new_name: this.workFlowOldName,
              status_ids,
              logContent //日志
            }

            // console.log('logContent', logContent)
            // 发送请求前加载loading框
            this.loading = this.commonJsExtend.customLoading("#offer_tableBox", 4, '数据更新中,请稍候...');
            //发送请求
            api.updateWorkFlowStatus(data).then(res => {
              this.loading.close() //关闭loading框
              if (res.code == 200) {
                this.getWorkflowSetupById({
                  name: this.orderFlowClassfiyId
                })
                this.$message({
                  type: 'success',
                  message: '添加节点成功',
                  duration: 1500
                })
              } else {
                this.$message({
                  type: 'error',
                  message: res.message,
                  duration: 1500
                })
              }
            })
            //清空相关信息
            this.insertionVisible = false;
            this.currentIndex = '';
            this.currentId = '';
            this.cuurentWorkStatusId = []
            this.currentStatusId = ''
            this.insertData.insertionNode = ''
          }
        })
      },

      /* 删除流程中的节点 */
      reomveProcessNode(nodeData, statusArr, index) {
        //判断是否有删除权限
        if (this.commonJsExtend.isHaveThePower(22, 8, 3, '删除')) {
          return
        }
        this.$confirm('确定要删除该节点吗?', '警告', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          var status_ids = []
          for (let i = 0; i < statusArr.length; i++) {
            status_ids[i] = statusArr[i].id;
          }
          status_ids.splice(index, 1)
          var status_ids = status_ids.join(",")
          var data = {
            id: nodeData.id,
            name: nodeData.name,
            new_name: nodeData.name,
            status_ids,
          }
          // 发送请求前加载loading框
          this.loading = this.commonJsExtend.customLoading("#offer_tableBox", 4, '数据更新中,请稍候...');
          //发送请求
          api.updateWorkFlowStatus(data).then(res => {
            this.loading.close() //关闭loading框
            if (res.code == 200) {
              this.getWorkflowSetupById({
                name: this.orderFlowClassfiyId
              })
              this.$message({
                type: 'success',
                message: '删除成功',
                duration: 1500
              })
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: 1500
              })
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
            duration: 1500
          })
        })
      },

      /* 获取新增流程节点ID */
      getStatusId(id, indexI, data) {
        let is_same = false
        if (data == 'fromData') {
          data = this.fromData
          var formType = 'fromData'
          if (id == data.statusID[0]) {
            this.$message({
              type: 'error',
              message: '不能选择相同的节点',
              duration: this.elDuration
            })
            return false
          }
        } else {
          data = this.editData
          var formType = 'editData'
          if (id == data.startOne.id) {
            this.$message({
              type: 'error',
              message: '不能选择相同的节点',
              duration: this.elDuration
            })
            return false
          }
        }
        data.statusID.forEach((item, index) => {
          if (item == id) {
            this.$message({
              type: 'error',
              message: '不能选择相同的节点',
              duration: this.elDuration
            })
            this.is_same = true
            data.statusID.splice(index, 1)
          }
        })
        if (!is_same) {
          data.statusID.splice(indexI, 1, id)
        }
      },

      /* 获取作用单据 */
      getbillsClassifyId(data, id) {
        this.formData.billsModel = [];
        if (data == 'fromData') {
          data = this.fromData
        } else {
          data = this.editData
        }
        data.billsClassify = id;
      },

      /*获取节点id*/
      getInsertionNode(id) {
        this.currentStatusId = id
      },

      /* 获取开始节点 */
      getStarStatusId(data, id) {
        if (data == 'fromData') {
          data = this.fromData
          // data.statusID.splice(0, 1, id)
        } else {
          data = this.editData;
        }
        data.startOne.id = id;
      },

      /* 展示节点弹框*/
      show_addProcessNode(data, index, statusArr) {
        //判断是否有新增权限
        if (this.commonJsExtend.isHaveThePower(22, 8, 2, '新增')) {
          return
        }
        this.insertionVisible = true;
        this.currentIndex = index;
        this.currentId = data.id;
        this.workFlowOldName = data.name
        for (let i = 0; i < statusArr.length; i++) {
          this.cuurentWorkStatusId[i] = statusArr[i].id;
        }
      },

      /* 流程中节点交换顺序 */
      swapNode(nodeData, statusArr, index) {
        //判断是否有操作权限
        if (this.commonJsExtend.isHaveThePower(22, 8, 4, '操作')) {
          return
        }
        this.$confirm('确定要交换这两个节点吗?', '警告', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          var status_ids = []; //定义节点id数组
          var swap = 0; //作为id替换的变量
          let nodeNames = []; //定义节点名称数组
          for (let i = 0; i < statusArr.length; i++) {
            status_ids[i] = statusArr[i].id;
            nodeNames[i] = statusArr[i].name;
          }
          //交换两个节点位置
          swap = status_ids[index];
          status_ids[index] = status_ids[index + 1]
          status_ids[index + 1] = swap;
          status_ids = status_ids.join(",");
          let logContent = '将【' + this.billsClassifyName + '】的【' + nodeData.name + '】中的节点【' + nodeNames[
            index] + '】和节点【' + nodeNames[index + 1] + '】交换位置'
          console.log(logContent)
          var data = {
            id: nodeData.id,
            name: nodeData.name,
            new_name: nodeData.name,
            status_ids,
          }
          // 发送请求前加载loading框
          this.loading = this.commonJsExtend.customLoading("#offer_tableBox", 4, '数据更新中,请稍候...');
          //发送请求
          api.updateWorkFlowStatus(data).then(res => {
            this.loading.close() //关闭loading框
            if (res.code == 200) {
              this.getWorkflowSetupById({
                name: this.orderFlowClassfiyId
              })
              this.$message({
                type: 'success',
                message: '交换成功',
                duration: 1500
              })
            } else {
              this.$message({
                type: 'error',
                message: '交换失败',
                duration: 1500
              })
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消交换操作',
            duration: 1500
          })
        })
      },

      /* 显示编辑弹框 */
      show_Dialog(process) {
        //判断是否有操作权限
        if (this.commonJsExtend.isHaveThePower(22, 8, 4, '操作')) {
          return
        }
        this.getBilsTypeData();
        this.title = "编辑"
        this.ref = 'compile_ruleform'
        this.dialog = 'editData'
        const {
          id,
          type_classify, //单据分类
          is_default, //是否默认
          name, //名称
          range_type, //开始
          statusList, //过程
          billing_type_name, //单据模板
          billing_type_id //单据模板id
        } = process
        let billingList=[]
        let intArr=[]
        if(!!billing_type_id){
        billingList=billing_type_id.split(",")
          console.log(billingList)
          if(billingList.length>0){
            //循环处理未int数组
            billingList.forEach(item => {
              intArr.push(parseInt(item));
            })
          }
        }

        this.currentId = id
        this.workFlowOldName = name //名称
        this.editData = {
          name: name, //名称
          classifyType: this.billsClassifyData[type_classify].value, //单据分类
          default: is_default + '', //是否默认
          type: range_type + '',
          startOne: statusList[0], //开始
          startName: statusList[0].name, //开始名称
          billsModel: intArr, //模板id
        }
        console.log(this.editData)
        this.arr = statusList.filter((val, index, arr) => {
          return index !== 0;
        })
        this.arr.forEach(item => {
          if (item.name == '完成') {
            this.arr.pop()
          }
        })
        let newArr = []
        this.arr.forEach(item => {
          newArr.push(item.id)
        })
        this.editData.statusID = newArr
        this.formData = this.editData
        this.sourceData = JSON.parse(JSON.stringify(this.editData)) //将编辑前数据储存
        this.dialogVisible = true
      },

      /* 删除弹框添加过程 */
      removeWork(index) {
        this.arr.splice(index, 1);
        let newArr = []
        this.arr.forEach(item => {
          newArr.push(item.id)
        })
        this.editData.statusID = newArr
        this.fromData.statusID = newArr
      },

      /* 弹框添加过程 */
      addWork() {
        if (this.dialog == 'fromData') {
          if (!this.fromData.startOne.id) {
            this.$message({
              type: 'error',
              message: '请选择开始节点',
              duration: this.elDuration
            })
            return false
          }
        } else if (this.dialog == 'editData') {
          if (this.editData.startOne.length == 0) {
            this.$message({
              type: 'error',
              message: '请选择开始节点',
              duration: this.elDuration
            })
            return false
          }
        }
        this.arr.push({
          name: ''
        })
      },

      /*  表单验证规则 */
      initRules() {
        this.Add_Edit_rules = {
          name: [ //名称规则
            { //规则1
              required: true, //是否必填
              message: '请输入名称', //该规则错误信息提示
              trigger: 'blur' //哪种事件触发(blur为失去焦点事件)
            },
            { //规则2
              min: 1, //最小长度
              max: 10, //最大长度
              message: '长度在 1 到 10个字符', //该规则错误提示信息
              trigger: 'blur' //哪种事件触发(blur为失去焦点事件)
            }
          ],
          startName: [ //开始节点规格
            { //规则1
              required: true, //是否必填
              message: '请选择开始节点', //该规则错误信息提示
              trigger: 'blur' //哪种事件触发(blur为失去焦点事件)
            },
          ],
          classifyType: [{ //规则1
            required: true, //是否必填
            message: '请选择作用单据', //该规则错误信息提示
            trigger: 'blur' //哪种事件触发(blur为失去焦点事件)
          }, ]
        }
        this.insert_rules = {
          insertionNode: [ //名称规则
            { //规则1
              required: true, //是否必填
              message: '请选择节点', //该规则错误信息提示
              trigger: 'blur' //哪种事件触发(blur为失去焦点事件)
            },
          ],
        }
      },

      /* 拿到数组添加节点 */
      changeArray() {
        let res = this.orderFlowData.slice(0);
        let self = this;
        self.orderFlowData = []
        for (let i = 0; i < res.length; i++) {
          var nodeInfo = [];
          if (typeof res[i].status_ids != 'string') {
            return
          } else if (typeof res[i].status_ids == 'string') {
            res[i].status_ids = res[i].status_ids.split(',');
          }
          //循环流程单中的详细状态过程
          for (let j = 0; j < res[i].status_ids.length; j++) {
            for (let b = 0; b < self.statusList.length; b++) {
              if (Number(self.statusList[b].id) === Number(res[i].status_ids[j])) {
                nodeInfo.push({
                  id: self.statusList[b].id,
                  name: self.statusList[b].name,
                  isSystem: self.statusList[b].is_system
                })
              }
            }
          }
          self.orderFlowData.push({
            id: res[i].id,
            name: res[i].name,
            statusList: nodeInfo,
            companyId: res[i].company_id,
            create_time: res[i].create_time,
            updateTime: res[i].update_time,
            is_default: res[i].is_default,
            isOnline: res[i].is_online,
            range_type: res[i].range_type,
            type_classify: res[i].type_classify,
            billing_type_id: res[i].billing_type_id,
            billing_type_name: res[i].billing_type_name
          })
        }
      },

      /* 显示新增 */
      show_addBox() {
          //判断是否有新增权限
          if (this.commonJsExtend.isHaveThePower(22, 8, 2, '新增')) {
            return
          }
        this.getBilsTypeData();
        //设置默认信息
        if (this.billsClassifyData && this.billsModelData) {
          this.initData(this.billsClassifyData[0].value, this.billsModelData[0].id) //设置默认值
        }
        //设置弹框提示信息
        this.title = '新增流程';
        this.dialog = 'fromData';
        this.fromData.statusID = [];
        this.ref = 'Add_ruleform';
        this.formData = this.fromData;
        //显示弹框
        this.dialogVisible = true;
      },

      /* 获取当前分类下的相关数据 */
      getBilsTypeData() {
        //根据分类获取当前分类单据分类信息
        this.billsModelData = this.commonJsExtend.getBillsClassifyDataByType(0, this.curClassifyObjId, this
          .billTemplateData).children;
        //获取单据分类
        this.NodeClassfiy.forEach((itemI, indexI) => {
          if (itemI.id == this.orderFlowClassfiyId) {
            this.billingClassifyData.forEach((itemJ, indexJ) => {
              if (itemJ.code == itemI.code) {
                this.billsClassifyData = itemJ.data;
                this.billsClassifyName = itemJ.name
              }
            })
          }
        })
      },

      /* 初始化数据 */
      initData(val, id) {
        let classifyType = '' //分类
        let billsModel = [] //模板
        if (id) { //赋予默认值
          classifyType = val
          billsModel = id
        }
        //编辑内容初始化
        this.editData = { //编辑信息
          name: '', //编辑字符串
          classifyType: '', //作用单据分类
          startName: '', //开始名称
          default: '0', //默认
          type: '0', //类型
          billsModel: [], //单据模板
          startOne: [], //开始数组
          statusID: [], //过程
        };
        //新增内容初始化
        this.fromData = { //新增弹框数据
          name: '',
          classifyType, //作用单据分类
          startName: '', //开始名称
          default: '0', //默认
          type: '0', //类型
          billsModel:[], //单据模板
          startOne: [], //开始数组
          statusID: [], //过程
        };
      },

      /* 关闭 新增 / 编辑 弹框 */
      cancleWorkFlow() {
        this.initData()
        //新增过程数据初始化
        this.arr = [];
        this.$refs[this.ref].resetFields(); //移除表单验证规则
        //表单内容初始化
        this.title = ''; //弹框标题
        this.dialog = ''; //区分新增和修改表
        this.formData = {}; //弹框表单数据
        this.ref = ''; //dom标记
        this.dialogVisible = false;
      },

      /* 选中选择框时触发 */
      selectChange(element) {
        this.$refs[this.ref].clearValidate([element])
      },

      /* 关闭插入弹框 */
      cancleWorkFlowInsert() {
        this.insertData.insertionNode = ''
        this.$refs['insert_ruleform'].resetFields(); //移除表单验证规则
        this.insertionVisible = false;
      },
    }
  }
</script>

<style lang="less">
  .el-divider {
    width: 100%;
    height: 0.5px;
    background-color: #DCDFE6;
    margin: 10px 0 !important;
  }

  .workflowBox {
    height: 100%;
    width: 100%;

    .addWorkBox {
      height: 300px;
      overflow: auto;

      .el-icon-circle-plus:hover {
        color: #409eff;
      }
    }

    // border: 1px solid black;
    //头部新增按钮
    .workflowHead {
      width: 100%;
      height: 40px;
      line-height: 40px;
      // border: 1px solid black;
    }

    //流程内容
    .workflowBody {
      width: 100%;
      height: calc(100% - 100px);
      overflow: auto;

      // border: 1px solid black;
      // 单个流程
      .workflowItem {
        border-radius: 3px;
        margin: 10px auto 50px auto;
        //height: 23%;
        padding: 10px;
        // border: 1px solid black;
        box-shadow: 0px 0px 5px gray;
        width: 90%;

        /* 流程相关 */
        .workflowItemBody {
          width: 100%;
          display: flex;
          justify-content: center;
          text-align: center;
          margin: 35px auto;

          .processNodeBox {
            width: auto;
            margin-bottom: 15px;
            display: inline-block;

            p {
              font-size: 12px;
              color: gray;
              margin-top: 3px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            p:hover {
              cursor: default;
              color: #409eff;
              display: inline-block
            }

            .processNodeText {
              width: 50px;
              height: 50px;
              cursor: default;
              text-align: center;
              align-items: center;
              position: relative;
              display: inline-block;
              float: left;

              .processNodeAb {
                display: none;

                .icon-shanchu {
                  color: #20A0FF;
                }

                i {
                  font-size: 24px;
                }
              }
            }

            .processNodeText:hover .processNodeName,
            .processNodeText:hover p {
              display: none;
            }

            .processNodeText:hover .processNodeName p {
              display: none;
            }

            .processNodeText:hover .processNodeAb p {
              display: inline-block
            }

            .processNodeText:hover .processNodeAb p {
              color: #20A0FF;
            }

            .processNodeText:hover .processNodeAb {
              display: flex;
              justify-content: center;
              flex-flow: column;
            }

            .processNodeLine {
              width: 40px;
              margin: 0 4px;
              display: inline-block;
              float: left;

              .icon-paixu {
                color: gray;
              }

              .icon-add2 {
                color: gray;
              }

              .icon-add2:hover,
              .icon-paixu:hover {
                color: #409eff;
              }

              div {
                width: 100%;
                height: 2px;
                background: #ccc;
                display: block;
                position: relative;
                margin: 5px 0px;
              }
            }
          }

          .processNodeBox .processNodeSty:hover .processNodeName,
          .processNodeBox .processNodeSty:hover p {
            display: flex;
            justify-content: center;
          }

          .processNodeBox .processNodeSty:hover .processNodeAb {
            display: none
          }

          .processNodeName {
            background-color: white;
            width: 23px;
            height: 23px;
            line-height: 23px;
            border: 2px solid gray;
            text-align: center;
            border-radius: 50%;
            color: gray;
            margin: 0 auto;
            position: relative;
          }
        }

        // 头部基本信息和操作框
        .workflowItemTitle {
          display: flex;
          justify-content: space-between;

          // border: 1px solid red;
          .itemTitleName {
            //流程名样式
            // border: 1px solid red;
            font-size: 18px;
            font-weight: 700;
          }

          .itemTitleTime {
            //日期样式
            color: gray;
          }

          .itemTitleOperation {
            //操作样式
            display: flex;

            .choiced {
              background-color: #409eff;
              border: 1px solid #409eff !important;
              position: relative;
            }

            .choiced::before {
              position: absolute;
              left: 50%;
              top: 50%;
              background: #fff;
              width: 5px;
              height: 5px;
              border-radius: 100%;
              content: "";
              transform: translate(-50%, -50%) scale(1);
            }

            .alibabaicon {
              margin: 0px 5px;
            }

            i:hover {
              color: #20A0FF;
            }

            .orderIsDefault {
              border: 1px solid #ccc;
              border-radius: 100%;
              width: 14px;
              height: 14px;
              display: inline-block;
              margin-right: 3px;
              cursor: pointer;
              vertical-align: middle
            }
          }
        }
      }

      .workflowItem:hover {
        background-color: #eeeeee;
      }
    }
  }
</style>
